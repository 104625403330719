html, body {
    height: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.login-form {
    padding-top: 30px;
    display: grid;
    width: 30em;
    grid-template-columns: 10em 1fr;
    grid-gap: 1em;
}

@media screen and (max-width: 720px){
    .login-form {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    }
}

label {
    grid-column: 1 / 2;
}

input, button {
    grid-column: 2 / 3;
}

.chooser-container {
}